.form-group {
  position: relative;
  &.input-group {
    & > .form-control {
      z-index: initial;
    }
    .input-group-prepend {
      position: absolute;
      top: 1px;
      left: 1px;
      bottom: 1px;
      margin: 0;
      z-index: 1;
      & + .form-control {
        border-radius: $input-border-radius;
        padding-left: 55px;
      }
    }
    .input-group-text {
      border-width: 0;
      padding: 0 0 0 18px;
      font-size: $font-size-base * 2;
      width: 40px;
    }
    .form-control-placeholder {
      left: 56px;
    }
  }
}

.form-control {
  transition: all 200ms;
  &::placeholder {
    color: $transparent;
  }
  &:focus,
  &:not(:placeholder-shown) {
    padding: $input-padding-y * 1.25 $input-padding-x $input-padding-y * 0.625;
  }
  &--search {
    border-radius: 100px !important;
    padding-left: 55px;
    &:focus,
    &:not(:placeholder-shown) {
      padding: $input-padding-y $input-padding-x $input-padding-y 55px;
    }
    &:focus {
      & + .input-group-prepend {
        .input-group-text {
          color: $input-focus-border-color;
        }
      }
    }
    &::placeholder {
      color: $input-placeholder-color;
    }
  }
  &--without-label {
    padding: $input-padding-y $input-padding-x;
    &:focus,
    &:not(:placeholder-shown) {
      padding: $input-padding-y $input-padding-x;
    }
  }

  &--with-placeholder {
    &:focus {
      &::placeholder {
        color: $input-placeholder-color;
        opacity: 1;
      }
    }
  }

  &:disabled,
  &:read-only,
  &.is-disabled {
    color: $input-disabled-color;
    border-color: $input-disabled-border-color;
    background-color: $input-disabled-bg;
  }
  &-placeholder {
    color: $input-placeholder-color;
    position: absolute;
    top: 50%;
    left: $input-padding-x;
    transform: translateY(-50%);
    transition: all 200ms;
    margin: 0;
  }
  &:focus + .form-control-placeholder,
  &:not(:placeholder-shown) + .form-control-placeholder {
    font-size: $font-size-xsmall;
    text-transform: uppercase;
    top: 7px;
    transform: translate(0, 0);
    opacity: 1;
  }

  &.is-invalid {
    color: $pizazz;
    border-color: $pizazz;
    box-shadow: 0px 0px 4px rgba($pizazz, 0.12);
    background-image: url("../../images/is-invalid.svg");
    &:focus {
      border-color: $pizazz;
      box-shadow: 0px 0px 4px $pizazz;
    }
  }
  &.is-valid {
    border-color: $input-border-color;
    background-image: url("../../images/is-valid.svg");
    &:focus {
      border-color: $input-focus-border-color;
      box-shadow: $input-focus-box-shadow;
    }
    &:disabled {
      background-image: none;
    }
  }
}

textarea.form-control {
  & + .form-control-placeholder {
    top: 16px;
    transform: translateY(0);
  }
}

.invalid-feedback {
  display: block;
  background-color: rgba($pizazz, 0.08);
  color: $pizazz;
  font-size: $font-size-base;
  padding: 16px 20px;
  margin-top: -9px;
  margin-bottom: 1rem;
  text-align: left;
}

.form-checkbox {
  display: inline-flex;
  &__label {
    background-color: rgba($zumthor, 0.5);
    border: 1.5px solid $dodger-blue;
    box-sizing: border-box;
    border-radius: 4px;
    height: 24px;
    width: 24px;
    position: relative;
    transition: all 0.2s ease-in-out;
    &__icon {
      color: $transparent;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  &__input {
    display: none;
    &:checked {
      & + .form-checkbox__label {
        background-color: $dodger-blue;
        .form-checkbox__label__icon {
          color: $white;
          font-size: $font-size-smaller;
        }
      }
    }
  }
  &.is-invalid {
    .form-checkbox__label {
      border-color: $pizazz;
    }
  }
}

.form-radio {
  &__label {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    text-align: left;
    .fake-radio {
      display: flex;
      position: relative;
      &::before {
        background-color: rgba($zumthor, 0.5);
        border: 1.5px solid $dodger-blue;
        box-sizing: border-box;
        border-radius: 100px;
        content: "";
        display: inline-block;
        height: 32px;
        margin-right: 6px;
        position: relative;
        width: 32px;
      }
      &::after {
        background-color: $transparent;
        border-radius: 100px;
        content: "";
        height: 0;
        position: absolute;
        top: 50%;
        left: 6px;
        transition: background-color 0.2s ease-in-out;
        width: 0;
        transform: translateY(-50%);
      }
    }
  }
  &__input {
    display: none;
    &:checked {
      & + .form-radio__label {
        .fake-radio {
          &::after {
            background-color: $dodger-blue;
            height: 20px;
            width: 20px;
          }
        }
      }
    }
  }
}

.disabled,
:disabled {
  pointer-events: none;
  cursor: default;
}
