.my-account {
  padding: 56px 15px 70px;
  position: relative;

  &__wrapper {
    max-width: 960px;
  }

  &__form {
    max-width: 360px;
    margin: 0 auto;
    &-container{
      & > div{
        &:first-child {
          border-right: 1px dotted $spun-pearl;
        }
      }
    }
  }
}