body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
}

.wrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: $wrapper + $grid-gutter-width;
  padding-left: $grid-gutter-width / 2;
  padding-right: $grid-gutter-width / 2;
  width: 100%;
  &--large {
    max-width: $wrapper-large + $grid-gutter-width;
  }
}

b,
strong {
  font-weight: $font-weight-bold;
}

.grecaptcha-badge {
  visibility: hidden;
}
