.search-patients {
  padding: 157px 68px 68px;

  &-results {
    &__header {
      padding: 46px 20px 41px;
    }
    &__icon {
      color: rgba($dodger-blue, 0.36);
      font-size: $font-size-base * 5;
    }
  }
}