.spinner {
  background-color: rgba($white, 0.75);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;

  &__bullet {
    background-color: red;
    border-radius: 100px;
    display: inline-block;
    height: 8px;
    margin: 0 6px;
    width: 8px;
    animation: coloring 1s linear infinite;
    animation-fill-mode: both;
    &:nth-child(1) {
      background-color: #4d7efe;
      animation-delay: 0.5s;
    }
    &:nth-child(2) {
      background-color: #2cd6c1;
      animation-delay: 1s;
    }
    &:nth-child(3) {
      background-color: #f59400;
      animation-delay: 1.5s;
    }
    &:nth-child(4) {
      background-color: $bright-sun;
      animation-delay: 2s;
    }
  }
}

@keyframes coloring {
  0% {
    background-color: #4d7efe;
  }
  25% {
    background-color: $bright-sun;
  }
  50% {
    background-color: #f59400;
  }
  75% {
    background-color: #2cd6c1;
  }
  100% {
    background-color: #4d7efe;
  }
}
