.data-exchange {
  padding: 114px 0 144px;

  &__notification {
    font-size: $font-size-small;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    padding: 19px 20px;
    text-align: center;
    line-height: 1;
    &.info {
      background-color: $alice-blue-alt;
      color: $sapphire;
    }
    &.error {
      background-color: $provincial-pink;
      color: $alizarin-crimson;
    }
  }
}
