// Settings
@import "settings/variables";

// Bootstrap library
@import "bootstrap/scss/bootstrap.scss";

// React date picker library
@import '~react-day-picker/lib/style.css';

// // Core
@import "core/fonts";
@import "core/core";

// Mixins
@import "mixins/fluid-type";

// Modules
@import "modules/react-modal";
@import "modules/form";
@import "modules/day-picker-input";
@import "modules/google-places";
@import "modules/tabs";
@import "modules/card";
@import "modules/apex-charts";
@import "modules/react-autosuggest";

// Components
@import "components/icon";
@import "components/button";
@import "components/progress-bar";
@import "components/spinner";
@import "components/main-modal";
@import "components/provider-layout";
@import "components/provider-navigation";
@import "components/table";
@import "components/log-off-modal";
@import "components/invites-modal";
@import "components/decrypt-modal";
@import "components/header";
@import "components/dropdown";

// Views
@import "views/no-match";
@import "views/search-patients";
@import "views/data-exchange";
@import "views/financials";
@import "views/my-account";
@import "views/reports";
@import "views/provider-payment-plan";
@import "views/invites";

// Helpers
@import "helpers/helpers";
