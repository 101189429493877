.react-autosuggest {
  &__container {
    position: relative;
    width: 100%;
  }

  &__suggestions-container {
    background: $white;
    box-shadow: 0px 0px 24px rgba($black, 0.12);
    border-radius: 8px;
    max-height: 335px;
    overflow: auto;
    position: absolute;
    top: calc(100% + 8px);
    left: 38px;
    right: 38px;
    z-index: 1;
  }

  &__suggestions-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &__suggestion {
    border-top: 1px solid $athens-gray;
    padding: 20px 23px 23px;

    &--first {
      border-top-color: $transparent;
    }

    &--highlighted {
      background-color: $athens-gray;
    }
  }
}
