.invites {
  &__header {
    padding: 56px 18px 20px;
  }

  &__summary {
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 21px 14px 29px;
  }

  &__status {
    border-radius: 10px;
    font-size: $font-size-small;
    padding-right: 10px;
    min-width: 85px;

    &__icon {
      border-radius: 100px;
      height: 22px;
      width: 22px;
      margin-right: 5px;
    }

    &.pending {
      background-color: rgba($mustard, 0.19);
      color: $old-gold;

      .invites__status__icon {
        background-color: $mustard;
      }
    }

    &.clicked {
      background-color: rgba($dodger-blue, 0.19);
      color: $sapphire;

      .invites__status__icon {
        background-color: $dodger-blue;
      }
    }

    &.expired {
      background-color: rgba($burnt-sienna, 0.19);
      color: $burnt-sienna;

      .invites__status__icon {
        background-color: $burnt-sienna;
      }
    }
  }
}
