.tabs {
  &__tab-list {
    border: 1px solid $dodger-blue;
    border-radius: 8px;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 500px;
    width: 100%;
  }
  &__tab {
    flex: 1;
    color: $dodger-blue;
    cursor: pointer;
    padding: 7.5px 12px;
    &.is-active {
      background-color: $dodger-blue;
      color: $white;
    }

    &__header {
      padding: 42px 20px 25px;
      &--alt {
        padding-top: 60px;
        padding-bottom: 64px;
      }
    }
  }
}
