.decrypt-modal {
  max-width: 1024px;
  margin: auto;
  width: 100%;
  height: calc(75vh + 100px);
  position: relative;

  &__content {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    padding: 30px;
    height: 100%;

    .tabs__tab-panel {
      max-height: 75vh;
      overflow-y: auto;
    }
  }
}
