.invites-modal {
  max-width: 674px;
  margin: auto;
  width: 100%;

  &--confirm {
    max-width: 374px;
  }

  &__content {
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.08);
    padding: 30px;
  }
}
