@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .btn#{$infix}-auto {
      display: inline-block;
      width: auto;
    }
    .btn#{$infix}-block {
      display: block;
      width: 100%;
    }
  }
}

.btn {
  &-square {
    border-radius: 4px;
  }

  &-outline {
    &-primary-alice-blue {
      @extend .btn-outline-primary;
      background-color: $alice-blue;
    }
  }

  &.disabled,
  &:disabled {
    cursor: default;
  }

  &-primary {
    &.disabled,
    &:disabled {
      background-color: $athens-gray;
      border-color: $athens-gray;
      color: $french-gray;
    }
  }
}

.btns-options {
  display: flex;
  position: relative;
  margin-left: -4px;
  margin-right: -4px;
  @include media-breakpoint-down(3xs) {
    margin-left: -3x;
    margin-right: -3px;
  }
  justify-content: space-between;
  &__item {
    padding-left: 4px;
    padding-right: 4px;
    white-space: nowrap;
    @include media-breakpoint-down(3xs) {
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  &__close {
    position: absolute;
    left: calc(100% + 23px);
    top: 50%;
    transform: translateY(-50%);
    @include media-breakpoint-down(3xs) {
      transform: none;
      left: auto;
      top: auto;
      right: 0;
      bottom: calc(100% + 18px);
    }
  }
}
