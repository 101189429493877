.provider-layout {
  background-color: $white;
  height: 100vh;
  overflow: hidden;

  &__content {
    max-height: 100vh;
  }
}

.provider-form {
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;

  &--large {
    max-width: 594px;
  }
}

.provider-padlock {
  font-size: $font-size-base * 3;
}

.provider-verification-code {
  font-size: $font-size-base * 5.1875;
}

.provider-login {
  font-size: $font-size-base * 2.5;
}
