.provider-payment-plan {
  padding: 56px 0 35px;

  &__steps {
    max-width: 300px;

    & > * {
      position: relative;

      &::before {
        background-color: $white;
        border: 1px solid $dodger-blue;
        border-radius: 100px;
        display: block;
        content: "";
        height: 11px;
        outline: 2px solid $white;
        margin: 0 auto 8px;
        width: 11px;
        position: relative;
        z-index: 1;
      }

      &::after {
        background-color: $dodger-blue;
        display: block;
        content: "";
        position: absolute;
        height: 1px;
        top: 5px;
        width: 150%;
      }

      &:first-child {
        &::after {
          left: 50%;
        }
      }

      &:last-child {
        &::after {
          right: 50%;
        }
      }

      &.is-active {
        &::before {
          background-color: $dodger-blue;
        }
      }
    }
  }

  &__box {
    padding: 20px 0;

    & > * {
      padding: 0 30px;
      position: relative;

      &::after {
        background-color: rgba($dodger-blue, 0.14);
        content: "";
        height: 100%;
        width: 1px;
        position: absolute;
        top: 0;
        right: -0.5px;
      }

      &:last-child {
        &::after {
          content: none;
        }
      }
    }
  }

  &__checkbox {
    position: relative;

    label {
      border: 1px solid $french-gray;
      border-radius: 4px;
      color: $french-gray;
      padding: 12px 11px;
      cursor: pointer;
    }

    .icon {
      font-size: $font-size-base * 2.6875;
    }

    .radio {
      position: absolute;
      top: 12px;
      left: 12px;

      &:checked + label {
        border-color: $dodger-blue;
        color: $trout;
      }
    }
  }

  &__button {
    max-width: 356px;
  }

  &__back {
    left: -12px;
    top: 50%;
    transform: translateY(-50%);
  }
}
