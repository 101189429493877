.log-off-modal {
  max-width: 522px;
  margin: auto;
  width: 100%;
  &__content {
    background-color: #f8f8fa;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 5px;
    padding: 22px 34px 30px;
    position: relative;
    @include media-breakpoint-up(md) {
      padding: 40px 40px 52px;
    }
  }
  &__text {
    opacity: 0.9;
  }
  &__countdown {
    font-size: $font-size-base * 2.375;
    @include media-breakpoint-up(md) {
      font-size: $font-size-base * 2.8125;
    }
  }
  &__buttons {
    max-width: 354px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
    .btn {
      flex: 1;
    }
  }
}
