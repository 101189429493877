.main-modal {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  outline: 0;
  transition: 0.3s;
  &__overlay {
    background-color: rgba($black, 0.75);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 20px;
    z-index: 1050;
  }
  &__content {
    display: flex;
    outline: 0;
    width: 100%;
    position: relative;
  }
  &__close {
    background-color: transparent;
    position: absolute;
    top: 22px;
    right: 22px;
    border: none;
    transition: 0.3s;
    cursor: pointer;
    z-index: 1;
  }
}
