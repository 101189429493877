.dropdown {
  position: relative;

  &__content {
    opacity: 0;
    position: absolute;
    top: -100%;
    right: 0;
    transition: all 200ms ease-in-out;
    visibility: hidden;
    background-image: linear-gradient(-180deg, $white 0%, $white 100%);
    box-shadow: 0 1px 10px rgba($black, 0.2);
    border-radius: 3px;
    min-width: 200px;
  }

  &--active {
    .dropdown__content {
      opacity: 1;
      top: 100%;
      visibility: visible;
    }
  }
}
