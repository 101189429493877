.provider-navigation {
  background-color: $alabaster-alt;
  position: relative;
  width: 104px;
  
  &::before {
    content: "";
    position: absolute;
    right: 0;
    top: 0%;
    bottom: 0%;
    width: 4px;
    background: linear-gradient(-90deg, $black 0%, $transparent 100%);
    opacity: 0.12;
  }
  
  &__item {
    align-items: center;
    color: $jumbo;
    display: flex;
    flex-direction: column;
    font-size: $font-size-base * 0.6875;
    height: 104px;
    justify-content: center;
    justify-content: space-evenly;
    padding: 24px 12px 12px;
    text-align: center;
    text-decoration: none;
    width: 100%;

    &:hover,
    &.is-active {
      background-color: #6CB0FE;
      color: $white;
      text-decoration: none;
    }
  }

  &__avatar {
    border-radius: 4px;
    padding: 18px;

    &__btn {
      &:focus {
        outline: 0 none;
      }
    }

    &__img {
      border-radius: 100px;
      object-fit: cover;
      height: 44px;
      width: 44px;
    }

    &__close {
      position: absolute;
      top: 12px;
      right: 12px;
    }

    &.is-open {
      box-shadow: 0px 4px 50px rgba($black, 0.16);
      height: 80px;
      position: relative;
      width: 426px;
      padding: 12px 18px;
      z-index: 1;
    }
  }
}
